.site-header,.related-recipe-list,.site-footer {
	display: none !important;
}

* {
	overflow: visible !important;
}

.row,ol,ul {
	display: block !important;
}